<template>
  <div class="affairList-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <!-- <div class="screenBtn" @click="() => screenFun()">
        数据投屏
      </div> -->
      <input type="button" class="screenBtn" @click="() => screenFun()" value="数据投屏" name="button">
    <el-table :data="tableData" border row-key="id" align="left"  @sort-change="tableSort">
      <el-table-column
        fixed
        prop="serviceName"
        label="客服名称"
        width="120px"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        fixed
        prop="address"
        label="dizhi"
        width="120px"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="riqi"
        width="120px"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="riqi"
        width="120px"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="riqi"
        width="120px"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="riqi"
        width="120px"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="riqi"
        width="180px"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="riqi"
        width="180px"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="riqi"
        width="180px"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="riqi"
        width="180px"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="riqi"
        width="180px"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="riqi"
        width="180px"
        sortable="custom"
      ></el-table-column>
      <!-- <el-table-column
        v-for="(item, index) in col"
        :key="`col_${index}`"
        :prop="dropCol[index].prop"
        :label="item.label"
        sortable="custom"
      >
      </el-table-column> -->
    </el-table>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import Sortable from "sortablejs";
export default {
  components: {
    Breadcrumb,
  },

  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "工单", isLink: false },
        { title: "工单列表", isLink: false },
      ],
      col: [
        {
          label: "日期",
          prop: "date",
        },
        {
          label: "日期",
          prop: "date",
        },
        {
          label: "日期",
          prop: "date",
        },
        {
          label: "日期",
          prop: "date",
        },
        {
          label: "日期",
          prop: "date",
        },
        {
          label: "日期",
          prop: "date",
        },
        {
          label: "日期",
          prop: "date",
        },
        {
          label: "姓名",
          prop: "name",
        },
        {
          label: "地址",
          prop: "address",
        },
      ],
      dropCol: [
        {
          label: "日期",
          prop: "date",
        },
        {
          label: "姓名",
          prop: "name",
        },
        {
          label: "地址",
          prop: "address",
        },
      ],
      tableData: [
        {
          id: "1",
          date: "2016-05-02",
          name: "王小虎1",
          address: "上海市普陀区金沙江路 100 弄",
        },
        {
          id: "2",
          date: "2016-05-04",
          name: "王小虎2",
          address: "上海市普陀区金沙江路 200 弄",
        },
        {
          id: "3",
          date: "2016-05-01",
          name: "王小虎3",
          address: "上海市普陀区金沙江路 300 弄",
        },
        {
          id: "4",
          date: "2016-05-03",
          name: "王小虎4",
          address: "上海市普陀区金沙江路 400 弄",
        },
      ],
    };
  },
  mounted() {
    // this.rowDrop();
    this.columnDrop();
  },
  methods: {
    
    screenFun() {
      // window.open("/dataScreen/index.html", '', 'channelmode=yes;fullscreen=yes')
      window.open(`/dataScreen/index.html`)
      // var target="/dataScreen/index.html"
      // let newwindow = window.open("/dataScreen/index.html")
      // newwindow.location = target

      // var width =  window.screen.width;
      // var height =   window.screen.height;
      // var elem = document.getElementById("div1");
      // this.requestFullScreen(window.open("/dataScreen/index.html"));
    },
    requestFullScreen(element) {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        }
    //FireFox
        else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        }
    //Chrome等
        else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
        }
    //IE11
        else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    },
    tableSort(e) {
      // 表格项排序
      let params = { ...this.params };
      params.sortField = e.prop;
      switch (e.order) {
        case "ascending":
          params.sortType = 1;
          break;
        case "descending":
          params.sortType = 2;
          break;
        default:
          params.sortType = null;
      }
      this.params = params;
      this.satisfiedDialog();
    },
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
        },
      });
    },
    //列拖拽
    columnDrop() {
      const wrapperTr = document.querySelector(" .el-table__header-wrapper tr");
      this.sortable = Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        onEnd: (evt) => {
          console.log(evt);
          const oldItem = this.dropCol[evt.oldIndex];
          this.dropCol.splice(evt.oldIndex, 1);
          this.dropCol.splice(evt.newIndex, 0, oldItem);
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.affairList-container {
  background: #fff;
  .screenBtn {
    padding: 4px 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    margin-bottom: 16px;
    cursor: pointer;
  }
}
</style>
